import { Capacitor } from '@capacitor/core';
import { LiveUpdate } from '@capawesome/capacitor-live-update';
import { makeAutoObservable, runInAction } from 'mobx';
import { PropsWithChildren } from 'react';

type AutoUpdateProviderProps = PropsWithChildren<{
  autoUpdateUrl?: string;
  autoUpdatePassword?: string;
  currentRevision?: string;
}>;

interface AutoUpdatePlatform {
  wasUpdateAlreadyApplied(revision: string): Promise<boolean>;
  downloadUpdate(url: string, revision: string): Promise<void>;
  applyUpdate(revision: string): Promise<void>;
}

class WebAutoUpdatePlatform implements AutoUpdatePlatform {
  async wasUpdateAlreadyApplied(revision: string) {
    return window.localStorage.getItem('autoUpdateRevision') === revision;
  }

  async downloadUpdate() {
    // no need to download since the browser will load the newest version
  }

  async applyUpdate(revision: string) {
    window.localStorage.setItem('autoUpdateRevision', revision);
    console.log('applying update to', revision);
    window.location.reload();
  }
}

class CapacitorAutoUpdatePlatform implements AutoUpdatePlatform {
  async wasUpdateAlreadyApplied(revision: string) {
    const bundle = await LiveUpdate.getBundle();
    return bundle?.bundleId === revision;
  }

  async downloadUpdate(url: string, revision: string) {
    await LiveUpdate.downloadBundle({
      url,
      bundleId: revision,
    });
    await LiveUpdate.setBundle({ bundleId: revision });
  }

  async applyUpdate(revision: string) {
    console.log('applying update to', revision);
    await LiveUpdate.reload();
  }
}

function createAutoUpdatePlatform() {
  if (Capacitor.isNativePlatform()) {
    return new CapacitorAutoUpdatePlatform();
  } else {
    return new WebAutoUpdatePlatform();
  }
}

export class AutoUpdateStore {
  static initializeSingleton(
    autoUpdateUrl: string,
    autoUpdatePassword: string,
    currentRevision: string
  ) {
    autoUpdateStore = new AutoUpdateStore(
      autoUpdateUrl,
      autoUpdatePassword,
      currentRevision
    );
  }

  constructor(
    private readonly autoUpdateUrl: string,
    private readonly autoUpdatePassword: string,
    private readonly currentRevision: string,
    private readonly platform: AutoUpdatePlatform = createAutoUpdatePlatform()
  ) {
    makeAutoObservable(this);
  }

  updateState:
    | { type: 'idle' }
    | { type: 'checking' }
    | { type: 'awaiting-update'; apply: () => void } = { type: 'idle' };

  async checkForUpdate() {
    if (this.updateState.type !== 'idle') {
      return;
    }

    if (
      !this.autoUpdateUrl ||
      !this.autoUpdatePassword ||
      !this.currentRevision
    ) {
      console.log(
        'No auto-update URL, password, or current revision provided. Skipping update...'
      );
      return;
    }

    console.log(`Checking for update at ${this.autoUpdateUrl}/latest`);

    runInAction(() => (this.updateState = { type: 'checking' }));
    try {
      const { revision, url } = await this.fetchLatestRevision();

      if (!revision || !url) {
        console.warn(
          'No revisions have been uploaded to the auto-update server. Skipping update...'
        );
        runInAction(() => (this.updateState = { type: 'idle' }));
        return;
      }

      if (this.currentRevision === revision) {
        console.log(
          'Current revision is already the latest, skipping update...'
        );
        runInAction(() => (this.updateState = { type: 'idle' }));
        return;
      }

      const wasUpdateAlreadyApplied =
        await this.platform.wasUpdateAlreadyApplied(revision);

      if (wasUpdateAlreadyApplied) {
        console.log(
          "This build's revision is not the latest, but we already applied an update to this revision. Current build revision:",
          this.currentRevision,
          'latest revision:',
          revision
        );
        runInAction(() => (this.updateState = { type: 'idle' }));
        return;
      }

      await this.platform.downloadUpdate(url, revision);

      console.log('update downloaded, awaiting update');
      runInAction(
        () =>
          (this.updateState = {
            type: 'awaiting-update',
            apply: () => this.platform.applyUpdate(revision),
          })
      );
    } catch (error) {
      console.error('Error checking for update:', error);
      runInAction(() => (this.updateState = { type: 'idle' }));
    }
  }

  applyUpdateIfNeeded() {
    if (this.updateState.type === 'awaiting-update') {
      setTimeout(() => {
        if (this.updateState.type === 'awaiting-update') {
          this.updateState.apply();
        }
      }, 2000);
      return true;
    }
    return false;
  }

  private async fetchLatestRevision() {
    const response = await fetch(`${this.autoUpdateUrl}/latest`, {
      headers: {
        Authorization: `Bearer ${this.autoUpdatePassword}`,
      },
    });

    console.log(`Received response with status: ${response.status}`);
    const data = await response.json();
    console.log(`Received data:`, data);
    return { revision: data?.revision, url: data?.url };
  }
}

export let autoUpdateStore: AutoUpdateStore;
